import React from 'react';

const HeroSection = () => {
  return (
    <div className="heroBkg_4810">
      <header className="white">
        <div className="container text-center">
          <div>
            <div style={{ margin: '300px 0 50px 0' }} className="hideSm">
              <img src="images/RewardYourself_horz.png" alt="" />
            </div>

            <div style={{ margin: '130px 0 50px 0' }} className="hideLg">
              <img
                src="images/RewardYourself_vert.png"
                style={{ width: '300px' }}
                alt=""
              />
            </div>

            <div className="arrow bounce whiteArrow">
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Career"
              >
                &nbsp;
              </a>
            </div>
          </div>
        </div>
      </header>

      <div className="top-left darkBkg white size10">
        <b>Form 4810D R-6/24</b>
      </div>
    </div>
  );
};

export default HeroSection;
