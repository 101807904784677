import React from 'react';

interface NextStepSectionProps {
  hasRep?: boolean;
}

const NextStepSection = ({ hasRep }: NextStepSectionProps) => {
  return (
    <section id="Step">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4810/images/step_dsk.jpg"
                alt="Middle aged male Sales Representative welcomes young couple to WoodmenLife with a handshake"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4810/images/step_mbl.jpg"
                alt="Middle aged male Sales Representative welcomes young couple to WoodmenLife with a handshake"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Take the Next Step</h1>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '10px 0px 0px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4810/images/step_accent1.jpg"
                alt="African American Sales Representative smiles during onboarding process"
                width="400"
              />
            </div>

            <p>
              Beginning any new career is a major transition, and we&#8217;re
              here to help you by offering:
            </p>

            <div style={{ margin: '5px 0 0 0px' }}>
              <ul>
                <li style={{ margin: '0 0 0 -7px' }}>
                  A competitive compensation
                </li>
                <li style={{ margin: '0 0 0 -7px' }}>
                Online access to hundreds of free, customizable ads and sales brochures
                </li>
                <li style={{ margin: '0 0 0 -7px' }}>
                A laptop with applications, forms, sales presentations, and a direct mail database system loaded onto it
                </li>
                <li style={{ margin: '0 0 0 -7px' }}>Access to host virtual meetings with your members</li>
                <li style={{ margin: '0 0 0 -7px' }}>
                  A full library of content for selling via social media and
                  email
                </li>
                <li style={{ margin: '0 0 0 -7px' }}>
                  Sales tracking software
                </li>
                <li style={{ margin: '0 0 0 -7px' }}>Additional sales training opportunities and tools</li>
              </ul>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xs-4 mx-auto bg-accentnavy">
            <h1 className="Oswald WLblue">I'm ready to get started.</h1>

            <h2 className="Oswald white">
              Visit{' '}
              <a href="https://www.Woodmenlife.org/Careers/Sales" className="white">
              Woodmenlife.org/Careers/Sales
              </a>{' '}
              to learn about becoming part of something bigger.
            </h2>
          </div>


                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 mx-auto" style={{ margin: '30px 0 0 0' }}>
                        <p className="centerText size10">
                          Representatives are independent contractors and are not employees of WoodmenLife. 
                          However, certain full-time life insurance sales agents may be treated as statutory employees 
                          for employment tax purposes (See: IRS Publication 15-A).
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
         


          <div className="col-lg-10 mx-auto">
            {hasRep && (
              <div
                className="arrow bounce blueArrow"
                style={{ margin: '20px 0 150px 0' }}
              >
                <a
                  className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                  href="#Connect"
                >
                  <span className="sr-only">Connect</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NextStepSection;
