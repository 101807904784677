import React from 'react';

const UpgradeSection = () => {
  return (
    <section id="Upgrade">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4810/images/upgrade_dsk.jpg"
                alt="Mediterranean cruise ship docked at port"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4810/images/upgrade_mbl.jpg"
                alt="Mediterranean cruise ship docked at port"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Upgrade Your Lifestyle</h1>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '10px 0px 60px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4810/images/upgrade_accent1.jpg"
                alt="Woman hugging two children and pointing to the Leaning Tower of Pisa"
                width="400"
              />
            </div>

            <p>
              <h2 className="Oswald navy">Recognition</h2>
              We know how hard you&#8217;ll be working, and you deserve to be
              recognized for that.
            </p>

            <p>
              Our highest performers are rewarded with an annual expenses-paid,
              luxury vacation for two. Recent trips have included Ireland, Walt
              Disney World, Maui, and a Mediterranean cruise.
            </p>

            <p>
              <h2 className="Oswald navy">Bonuses</h2>
              You can qualify for several bonuses each year for accomplishments like attracting new WoodmenLife members and exceeding your sales goals. 
              Our top Representatives rank as Super Star and Super Star Elite status. In addition to lucrative bonuses, they receive extra perks like dedicated customer service and added recognition.
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Help"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>

    </section>
  );
};

export default UpgradeSection;
