import React from 'react';

const DreamCareerSection = () => {
  return (
    <section id="Career">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4810/images/career_dsk.jpg"
                alt="Smiling young female Sales Representative talking with prospective client"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4810/images/career_mbl.jpg"
                alt="Smiling young female Sales Representative talking with prospective client"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Build Your Dream Career</h1>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '10px 0px 0px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4810/images/career_accent1.jpg"
                alt="Male Sales Representative using laptop"
                width="400"
              />
            </div>


            <h2 className="Oswald navy">Build Your Future</h2>
            <p>Now is the time to start building the future you want. No matter what your aspirations are, we&#8217;re here to help you get to where you want to be.</p>
            <h2 className="Oswald navy">Learn More</h2>
            <p>Through specialized training and webinars, you&#8217;ll learn the basics of advanced sales concepts &mdash; all designed to help you grow your career with confidence.</p>
            <h2 className="Oswald navy">Find Your Balance</h2>
            <p>With a flexible work schedule, you&#8217;ll find the career-life balance that&#8217;s right for you.</p>
            <h2 className="Oswald navy">Rewards for Results</h2>
            <p>Because WoodmenLife wants you to be rewarded for your successes, we give you the training you&#8217;ll need to create new opportunities and make the income you want.</p>

          </div>
      </div>
    </div>


    <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">           


            <div className="centerText size14">
              <b>Average Annual Earnings</b>
            </div>  

             

            <div className="container">
              <div className="row">
                <div className="col-lg-5 mx-auto"> 

                    <div className="centerImg"> 
                      <img
                        src="/brochure/4810/images/earnings_graphic.png"
                        alt="Graph illustrating average annual salaries of WoodmenLife Top Qualifiers and Qualifiers"
                        width="400"
                        className="img-responsive"
                        style={{ margin: '10px 0 0 0' }}
                      />
                    </div>

                  </div>
              </div>
            </div>

            

            <div style={{ margin: '5px 0 50px 0' }}>
              <p className="centerText">
                You can make even more by selling products through our
                subsidiaries!
                <br />
              </p>
              <p className="centerText size10">
                * Club/Cabinet-level Incentive Trip Qualifier as of Dec. 31, 2023
                <br />
                ** Super Star/Elite Incentive Trip Qualifier as of Dec. 31, 2023
                <br />
              </p>
              
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-10 mx-auto">
                        <p className="centerText size10">
                          Representatives are independent contractors and are not employees of WoodmenLife. 
                          However, certain full-time life insurance sales agents may be treated as statutory employees 
                          for employment tax purposes (See: IRS Publication 15-A).
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>


            </div>




          </div>
        </div>
      </div>


      
      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Difference"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default DreamCareerSection;
