import React from 'react';

interface FooterProps {
  showEO?: boolean;
  wlLink?: string;
}
 
export const BrochureFooter = ({ showEO, wlLink }: FooterProps) => {

  return (
    <footer className="py-5 bg-navy">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 mx-auto white"
            style={{ marginTop: '0px', textAlign: 'center' }}
          >
            <a className="js-scroll-trigger" href="#page-top">
              <img
                src="images/logo.png"
                style={{ width: '200px', marginBottom: '10px' }}
                alt="woodmen logo"
              />
            </a>
            <br />

            <p className="m-0 text-center white size12">
              Woodmen of the World Insurance Society
              <br />
              Home Office: Omaha, Nebraska
              <br />
              1-800-225-3108
              <br />
              <br />
              <span className="blueLink">
                <a href={wlLink} target="_blank">
                  woodmenlife.org{wlLink}
                </a>
              </span>
            </p>
            {showEO && (
              <div className="white size12" style={{ margin: '5px 0 0 0' }}>
                <i>An Equal Opportunity Organization</i>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};
