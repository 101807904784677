import React from 'react';

const DifferenceSection = () => {
  return (
    <section id="Difference">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4810/images/difference_dsk.jpg"
                alt="WoodmenLife members unload pickup at community event"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4810/images/difference_mbl.jpg"
                alt="WoodmenLife members unload pickup at community event"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Make a Difference</h1>
            <p>
            With a WoodmenLife career, you&#8217;ll be helping families and your community every single day. This includes:
            </p>

            <ul>

              <li style={{ margin: '0 0 0 -7px' }}>
                Preparing families for their financial futures
              </li>
              <li style={{ margin: '0 0 0 -7px' }}>
                Helping WoodmenLife members qualify for benefits like scholarships and financial assistance following natural disasters
                <span className="size12">
                  <sup>1</sup>
                </span>{' '}
              </li>
              <li style={{ margin: '0 0 0 -7px' }}>
                Presenting American flags and recognizing First Responders and
                Veterans
              </li>
              <li style={{ margin: '0 0 0 -7px' }}>
                Helping to fight hunger in your community
              </li>
              <li style={{ margin: '0 0 0 -7px' }}>
                Assisting local nonprofit organizations
              </li>
              <li style={{ margin: '0 0 0 -7px' }}>
                Comforting families after loss
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">
            <div style={{ margin: '20px 0 0 0' }} className="size10">
            1. WoodmenLife Extras are available to members. An individual becomes a member by joining our shared commitment to family, community and country, and by purchasing a WoodmenLife product. 
            These extras are not contractual, are subject to change and have specific eligibility requirements, such as length of membership, number of qualifying members in household and/or qualifying event.
            </div>

          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Schedule"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>

    </section>
  );
};

export default DifferenceSection;
