import React from 'react';

const SucceedSection = () => {
  return (
    <section id="Support">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4810/images/support_dsk.jpg"
                alt="Diverse group of smiling Sales Representatives in a meeting"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4810/images/support_mbl.jpg"
                alt="Diverse group of smiling Sales Representatives in a meeting"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Succeed with Support</h1>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '10px 0px 40px 40px',
                backgroundColor: '#ffffff',
              }}
              className="img-responsive hideSm"
            >
              <img
                src="/brochure/4810/images/MapHomeOffice_D4810.svg"
                alt="Map of the US with a pin marking WoodmenLife Headquarters: Omaha, NE"
                width="400"
              />
            </div>

            <p>
              <h2 className="Oswald navy">You&#8217;re Not Alone</h2>
              You&#8217;ll have support both nationally and locally, working with your local Recruiting Sales Manager and Regional Director to set and accomplish your personal goals.
            </p>

            <p>
            You&#8217;ll also have the support of a local Community Outreach Advisor, who can help you with planning chapter events, arranging media coverage and more. In addition, our members are our best advocates; working with them closely will not only help your community, it can also result in referrals and continued business growth for you.
            </p>

            <div
              style={{
                width: '100%',
                margin: '10px 0px 40px 0px',
                backgroundColor: '#ffffff',
              }}
              className="img-responsive hideLg"
            >
              <img
                src="/brochure/4810/images/MapHomeOffice_D4810.svg"
                alt="Map of the US with a pin marking WoodmenLife Headquarters: Omaha, NE"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Upgrade"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>

      
    </section>
  );
};

export default SucceedSection;
