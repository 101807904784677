import React from 'react';

const LiveLifeSection = () => {
  return (
    <section id="Schedule">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4810/images/schedule_dsk.jpg"
                alt="Male Sales Representative is greeted by his young children at the door"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4810/images/schedule_mbl.jpg"
                alt="Male Sales Representative is greeted by his young children at the door"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Live Life on Your Schedule</h1>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '10px 0px 40px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4810/images/schedule_accent1.jpg"
                alt="Smiling female Sales Representative talking with prospective client "
                width="400"
              />
            </div>

            <p>
              <h2 className="Oswald navy">Control Your Hours &#8212; Control Your Success</h2>
              We know that you have a life outside of work and need time for family and other activities. Our Representatives are responsible for following up on prospect leads as they come in, 
              but they still have control over their own schedules.
            </p>

            <p>
              <h2 className="Oswald navy">Options for Success</h2>
              You&#8217;ll focus on selling to families, but we also provide opportunities to offer products to seniors, small businesses and First Responders. 
              We&#8217;ll even help cover some of the costs of getting your securities license if you want to sell investment products. These additional sales opportunities not only can increase your income, 
              they also can set you up as a one-stop service for your members.
            </p>

            <div
              style={{
                width: '100%',
                margin: '20px 0px 20px 0px',
                backgroundColor: '#ffffff',
              }}
              className="hideLg"
            >
              <img
                src="/brochure/4810/images/schedule_accent1.jpg"
                alt="Smiling female Sales Representative talking with prospective client "
                className="img-responsive"
              />
            </div>

            <p>
              <h2 className="Oswald navy">As a full-time WoodmenLife Representative, you may qualify for a full benefits package.</h2>
              From affordable health, dental and vision insurance to a 401(k) plan, WoodmenLife&#8217;s benefits help you take care of yourself and your family, just as you take care of our WoodmenLife member families every day. 
             </p>
            <p> 
              Visit <a href="http://www.WoodmenLife.org/Careers/Sales/Benefits"><strong>WoodmenLife.org/Careers/Sales/Benefits</strong></a> to find out more about our benefits plans and see how they might fit your needs.               
            </p>


                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8 mx-auto">
                        <p className="centerText size10">
                          Representatives are independent contractors and are not employees of WoodmenLife. 
                          However, certain full-time life insurance sales agents may be treated as statutory employees 
                          for employment tax purposes (See: IRS Publication 15-A).
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>

          </div>
        </div>
      </div>



      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Benefits"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default LiveLifeSection;
