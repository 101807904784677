import React from 'react';

const HelpSection = () => {
  return (
    <section id="Help">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4810/images/help_dsk.jpg"
                alt="Smiling female Sales Representative addresses a group of people"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4810/images/help_mbl.jpg"
                alt="Smiling female Sales Representative addresses a group of people"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">
              Help Members, <br className="hideLg" />
              Help Yourself
            </h1>

            <p>
            Plus, we continually update our products as markets and consumer needs change. We also make it convenient for you to help your members via virtual meetings and electronic applications that can easily be emailed. Here&#8217;s a look at what you could be selling:
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-1 hideSm"></div>
          <div className="col-lg-5 col-xs-12">
            <div>
              <h2 className="Oswald navy">Life Insurance</h2>

              <ul>
                <li style={{ margin: '0 0 0 -7px' }}>Whole Life</li>
                <li style={{ margin: '0 0 0 -7px' }}>Term Life</li>
                <li style={{ margin: '0 0 0 -7px' }}>Family Term</li>
                <li style={{ margin: '0 0 0 -7px' }}>Universal Life</li>
              </ul>
            </div>

            <div>
              <h2 className="Oswald navy">Annuities</h2>

              <ul>
                <li style={{ margin: '0 0 0 -7px' }}>Fixed Annuities</li>
                <li style={{ margin: '0 0 0 -7px' }}>IRAs</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-5 col-xs-12">
            <div>
              <h2 className="Oswald navy">
                Investments
                <span className="RegMark2">
                  <sup>3</sup>
                </span>
              </h2>

              <ul>
                <li style={{ margin: '0 0 0 -7px' }}>
                  529 College Savings Plans
                </li>
                <li style={{ margin: '0 0 0 -7px' }}>Mutual Funds</li>
                <li style={{ margin: '0 0 0 -7px' }}>
                  Variable Annuities
                </li>
              </ul>
            </div>

            <div>
              <h2 className="Oswald navy">
                Additional Insurance Options
                <span className="RegMark2">
                  <sup>4</sup>
                </span>
              </h2>

              <ul>
                <li style={{ margin: '0 0 0 -7px' }}>
                  Health Insurance (Individual and Group plans)
                </li>
                <li style={{ margin: '0 0 0 -7px' }}>
                  Impaired Risk Life Insurance
                </li>
                <li style={{ margin: '0 0 0 -7px' }}>Disability Insurance</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-1 hideSm"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div style={{ margin: '20px 0 0 0' }} className="size10">
              3. Securities are offered through Woodmen Financial Services, Inc. (WFS), 1700 Farnam Street, Omaha, NE 68102, 877-664-3332, member FINRA/SIPC, a wholly owned subsidiary of Woodmen of the World Life Insurance Society (collectively “WoodmenLife”). Securities other than the WoodmenLife Variable Annuity are issued by companies that are not affiliated with WoodmenLife. This material is intended for general use with the public. WFS is not providing investment advice for any individual or any individual situation, and you should not look to
              <br />
              4. WIA products are offered through Woodmen Insurance Agency, Inc., a wholly owned subsidiary of Woodmen of the World Life Insurance Society. WIA products are issued by carriers not affiliated with Woodmen of the World Life Insurance Society. All products may not be available in all states. Not all Representatives are licensed to sell all products. Representatives are independent contractors and are not employees of WoodmenLife.
            </div>

            
          </div>
        </div>
      </div>


      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Step"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>
    </section>
  );
};

export default HelpSection;
