import { Image } from '@chakra-ui/react';
import React from 'react';

import { FindProducerResponse } from '../../apis';
import MissingHeadshot from '../../images/missing-headshot.png';
import { formatPhoneNumber } from '../../utils/formatting.utils';
import {
  getRepresentativeName,
  getRepresentativeSite,
  getRepresentativeTitle,
  isRecruitingSalesManager,
} from '../../utils/representative.utils';

interface RepDetailsParams {
  producer: FindProducerResponse;
}

const RepDetails = ({ producer }: RepDetailsParams) => {
  const name = `${producer.firstName} ${producer.lastName}`;
  const image = React.useMemo(
    () =>
      producer.image ? (
        <Image src={producer.image} alt={`Image of ${name}`} />
      ) : (
        <Image src={MissingHeadshot as string} alt={`Image of ${name}`} />
      ),
    [producer.image, name]
  );
  const producerTitle = getRepresentativeTitle(producer);
  const producerName = getRepresentativeName(producer);
  const producerSite = producer.siteDirectory
    ? getRepresentativeSite(
        producer.siteDirectory,
        isRecruitingSalesManager(producer)
      )
    : null;

  return (
    <section id="Connect" className="bg-gray">
      <div className="container bg-gray">
        <div className="row">
          <div className="col-lg-3 hideSm">&nbsp;</div>
          <div className="col-lg-6 col-xs-8 mx-auto white">
            <h1
              className="Oswald white"
              style={{ marginBottom: '40px', textAlign: 'center' }}
            >
              Connect with WoodmenLife
            </h1>

            {producer.image && (
              <>
                <div className="RepImgContainer hideSm">{image}</div>

                <div
                  className="RepImgContainer hideLg"
                  style={{ textAlign: 'center', margin: '0 0px 30px 0' }}
                >
                  {image}
                </div>
              </>
            )}

            <div className="white mobiCenter">
              <b>{producerName}</b>
              <br />
              <i>{producerTitle}</i>
              <br />
              <br />
              {producer.city} {producer.state} {producer.zipCode}
              <br />
              {producer.phone && (
                <>
                  Office: {formatPhoneNumber(producer.phone)}
                  <br />
                </>
              )}
              {producer.mobilePhone && (
                <>
                  Cell: {formatPhoneNumber(producer.mobilePhone)}
                  <br />
                </>
              )}
              {producer.fax && (
                <>
                  Fax: {formatPhoneNumber(producer.fax)}
                  <br />
                </>
              )}
              <span className="blueLink">
                <a href={`mailto:${producer.email}`}>
                  <b className="WLblue">email</b>
                </a>
                <br />
              </span>
              {producerSite && (
                <span className="blueLink">
                  <a href={producerSite} target="_blank" rel="noreferrer">
                    <b className="WLblue">website</b>
                  </a>
                </span>
              )}
            </div>
          </div>
          <div className="col-lg-3 hideSm">&nbsp;</div>
        </div>
      </div>
    </section>
  );
};

export default RepDetails;
