import React from 'react';

const BenefitsSection = () => {
  return (
    <section id="Benefits">
      
     

    <div className="container" style={{ backgroundColor: '#bac8db'}}>
        <div className="row">
            <div className="col-lg-8 col-xs-12 mx-auto">
           
                <h1 className="Oswald" style={{ margin:'20px 0 0 0', color:'#002d62'}}>Benefits Available for You</h1>

                <p>WoodmenLife knows that you need more than just health insurance. See the chart below 
                for information on the plans we offer to cover yourself, your spouse and your children<sup>2</sup>.
                </p>


                
                    
                

                <table align="center">
                <tr>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', backgroundColor: '#002d62',}} className="white">
                    <b className="Oswald size24">Benefit</b>
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#002d62',}} className="white">
                    <b className="Oswald size24">Features</b>
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#ffffff',}} >
                    Health Insurance
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#ffffff',}} >
                  Choose from two plans with in-network and out-of-network benefits. 
                  Both include coverage for preventive care services.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#eaedf3',}} >
                    Retirement
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#eaedf3',}} >
                  Eligibility begins after nine months of full-time contract service. WoodmenLife will match up to 5%, and after one year of contract service, a service-related contribution is added.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#ffffff',}} >
                    Dental Insurance
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#ffffff',}} >
                  Choose from two plans with in-network and out-of-network benefits. Both include coverage for preventive, 
                  basic and complex services. You choose your plan and your dentist.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#eaedf3',}} >
                    Vision Insurance
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#eaedf3',}} >
                  Access one plan with exclusive pricing from network providers. Save on contact lenses, prescription glasses, sunglasses, and laser vision correction.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#ffffff',}} >
                    Legal and ID Protection
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#ffffff',}} >
                  Receive ID theft monitoring and legal support for an unlimited number of matters (estate planning, family law, document preparation, traffic offenses, etc.).
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#eaedf3',}} >
                    Voluntary Short-Term Disability<sup>*</sup>
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#eaedf3',}} >
                  You may purchase coverage for 60% of earnings up to $1,000 weekly.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#ffffff',}} >
                    Long-Term Disability<sup>*</sup>
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#ffffff',}} >
                  At no cost to you, receive 60% of earnings up to $5,000 monthly if disabled.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#eaedf3',}} >
                    Voluntary Long-Term Disability<sup>*</sup>
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#eaedf3',}} >
                  This personalized monthly benefit is designed to supplement your Long-Term Disability coverage.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#ffffff',}} >
                    Life Insurance<sup>*</sup>
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#ffffff',}} >
                  WoodmenLife provides coverage equal to your annual earnings. 
                  You may purchase additional coverage equal to one or two times your annual earnings.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#eaedf3',}} >
                    Associate Assistance Program
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#eaedf3',}} >
                  The Best Care Associate Assistance Program is a confidential benefit available 24/7 to you and your family, including your spouse and dependents, 
                  at no additional cost to you. You can receive up to eight free counseling sessions per situation.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#ffffff',}} >
                    Caregiver Services
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#ffffff',}} >
                  At no cost to you, Care Coaches are equipped to support you as you care for a loved one of any age.
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px', color: '#002d62', border: '1px solid #98b5d1', textAlign: 'left', width: '35%', fontWeight:'600', backgroundColor: '#eaedf3',}} >
                    Well-being Program
                  </td>
                  <td style={{padding: '10px', border: '1px solid #98b5d1', textAlign: 'left', width: '65%', backgroundColor: '#eaedf3',}} >
                  Take advantage of either a reduced health insurance premium if you enroll in WoodmenLife health benefits or a reimbursement amount if you don’t enroll in our health plan.
                  </td>
                </tr>
                </table>
                <br />



                <p>Most plans offer multiple options, covering you only or you and additional 
                family members.
                </p>

                <p className="centerText size10">
                
                *To qualify, earnings requirements must be satisfied. This information is for the 2024 plan year.<br />
                2. Available to only eligible Alpha contract Representatives.<br />                
                </p>
                <br />

            </div>
        </div>
    </div>


      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Support"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


      

    </section>
  );
};

export default BenefitsSection;
