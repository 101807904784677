import React from 'react';

interface NavSectionProps {
  hasRep?: boolean;
}

const NavSection = ({ hasRep }: NavSectionProps) => {
  return (
    <nav className="navbar navbar-custom fixed-top" id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top">
          <img
            src="/brochure/4810/images/logo.png"
            style={{ width: '200px' }}
            alt=""
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto" style={{ textAlign: 'center' }}>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Career">
                Build Your Dream Career
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Difference">
                Make a Difference
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Schedule">
                Live Life on Your Schedule
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Benefits">
              Benefits Available for You
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Support">
                Succeed with Support
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Upgrade">
                Upgrade Your Lifestyle
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Help">
                Help Clients, Help Yourself
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Step">
                Take the Next Step
              </a>
            </li>
            {hasRep && (
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#Connect">
                  Connect with WoodmenLife
                </a>
              </li>
            )}
            <li className="nav-item">&nbsp;</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavSection;
