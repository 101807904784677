import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import BenefitsSection from '../../../components/Brochure/4810/BenefitsSection';
import DifferenceSection from '../../../components/Brochure/4810/DifferenceSection';
import DreamCareerSection from '../../../components/Brochure/4810/DreamCareerSection';
import HelpSection from '../../../components/Brochure/4810/HelpSection';
import HeroSection from '../../../components/Brochure/4810/HeroSection';
import LiveLifeSection from '../../../components/Brochure/4810/LiveLifeSection';
import NavSection from '../../../components/Brochure/4810/NavSection';
import NextStepSection from '../../../components/Brochure/4810/NextStepSection';
import SucceedSection from '../../../components/Brochure/4810/SucceedSection';
import UpgradeSection from '../../../components/Brochure/4810/UpgradeSection';
import { BrochureFooter } from '../../../components/Brochure/BrochureFooter';
import RepDetails from '../../../components/Brochure/RepDetails';
import { useRepSearch } from '../../../hooks/useRepSearch';
import useScript from '../../../hooks/useScript';

const Brochure4810 = () => {
  const [repLookupComplete, repData] = useRepSearch();

  useScript(withPrefix('/brochure/js/jquery.min.js'));
  useScript(withPrefix('/brochure/js/bootstrap.bundle.min.js'));
  useScript(withPrefix('/brochure/js/jquery.easing.min.js'));
  useScript(withPrefix('/js/scrolling-nav.js'), !repLookupComplete);
  useScript(withPrefix('/js/zoom.js'));

  return (
    <>
      <Helmet>
        <html className="brochure" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>
          Form 4810D R-7/23 - WoodmenLife - Reward Yourself
        </title>

        <link href="/brochure/css/bootstrap.min.css" rel="stylesheet" />

        <link href="/brochure/css/styles.css" rel="stylesheet" />
        <link href="/brochure/css/overrides.css" rel="stylesheet" />
        <script src="https://kit.fontawesome.com/8f3009470a.js"></script>

        <link
          href="https://fonts.googleapis.com/css?family=Oswald:700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,800,800i"
          rel="stylesheet"
        />
      </Helmet>
      <body id="page-top">
        <NavSection hasRep={Boolean(repData)} />
        <HeroSection />
        <DreamCareerSection />
        <DifferenceSection />
        <LiveLifeSection />
        <BenefitsSection />
        <SucceedSection />
        <UpgradeSection />
        <HelpSection />
        <NextStepSection hasRep={Boolean(repData)} />
        {repData && <RepDetails producer={repData} />}
        <BrochureFooter wlLink={'/careers/sales/'} />
      </body>
    </>
  );
};

export default Brochure4810;
