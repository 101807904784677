import { useEffect, useState } from 'react';

import { ApiConfiguration, FindProducerResponse, ProducerApi } from '../apis';
import { isBrowser } from '../utils/browser.utils';

const useRepSearch = (): [boolean, FindProducerResponse | undefined] => {
  const [complete, setComplete] = useState<boolean>(false);
  const [repData, setRepData] = useState<FindProducerResponse | undefined>(
    undefined
  );
  const producerId = isBrowser()
    ? new URLSearchParams(window.location.search).get('r')
    : null;
  useEffect(() => {
    if (!producerId) {
      setComplete(true);
      return;
    }

    const api = new ProducerApi(
      new ApiConfiguration({
        basePath: process.env.GATSBY_PUBLIC_API_PATH,
      })
    );

    api
      .producerFindByIdPost({ singleProducerRequest: { producerId } })
      .then((producer) => {
        setRepData(producer);
      })
      .catch(() => {})
      .finally(() => {
        setComplete(true);
      });
  }, [producerId]);

  return [complete, repData];
};

export { useRepSearch };
